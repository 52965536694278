<template>
    <v-app>
        <v-progress-linear
                v-if="loadingState > 0"
                height="3"
                style="margin: 0; top: 0; z-index: 999; position: absolute"
                :indeterminate="true"
                color="info"
        ></v-progress-linear>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <router-view></router-view>
            </v-layout>
        </v-container>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import SnackDisplayer from "../common/SnackDisplayer";
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['selectedNavigationItem', 'getTopNavigationTitle']),
            loadingState() {
                return this.$store.getters['loadingState/loadingState'];
            },
        },
        components: {
            SnackDisplayer,
        },
    }
</script>

<style lang="scss">
    .invisible {
        visibility: hidden;
    }
</style>
