const AdminRootView = () => import(/* webpackChunkName: "admin" */ "../views/admin/AdminRootView");
import QuizListView from "@/views/admin/QuizListView";
import StudentsView from '../views/admin/StudentsView';
import EmptyView from '../views/common/EmptyView';

import routeGuardAdmin from "../routeGuards/routeGuardAdmin";
import HomeView from "../views/admin/HomeView";

export default (router) => {
    return [
        {
            path: '/admin',
            component: AdminRootView,
            name: 'adminRoot',
            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
            redirect: {name: 'adminHome'},
            children: [
                {
                    path: 'adminHome',
                    name: 'adminHome',
                    component: HomeView,
                },
                {
                    path: 'dictionaries',
                    component: EmptyView,
                    children: [
                        {
                            path: 'students/:faculty?/:speciality?/:semester?/:group?',
                            name: 'studentsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StudentsView,
                        },
                        {
                            path: 'quizList',
                            name: 'quizList',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: QuizListView,
                        },
                    ]
                },
            ],
        },
    ]
}