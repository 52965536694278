<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <template v-for="studNav in studNavigationItems">
                                <v-subheader class="mt-4 grey--text text--darken-1">{{studNav.name}}</v-subheader>
                                <navigation-list-template :items="studNav.specialities"/>
                            </template>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="!isSelectedSemester" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть факультет, спеціальність та курс
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <students-index :key="$route.fullPath" v-if="isSelectedSemester"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import StudentsIndex from "../../components/admin/Students/StudentsIndex";

    export default {
        components: {
            StudentsIndex,
            NavigationListTemplate
        },
        data: () => {
            return {
                studNavigationItems: [],
                isSelectedSemester: false,
                loading: false, 
            };
        },
        props: {
            routeTo: {
                type: String,
                default: "studentsGrid"
            },
        },
        mounted() {
            this.isSelectedSemester = this.$route.params.semester !== undefined;

            this.loading = true;
            
            HttpService.getInstance()
                .get("navigation/getNavGroups")
                .then(navItems => {
                    this.loading = false;
                    this.studNavigationItems = this.remapStudNavItems(navItems);
                })
        },
        watch: {
            $route: function(route, prevRoute) {
                if (route.params.semester !== prevRoute.params.semester) {
                    this.isSelectedSemester = route.params.semester !== undefined;
                }
            },
        },
        methods: {
            remapStudNavItems(studNavigationItems) {
                if (studNavigationItems === undefined) {
                    return [];
                }

                let studNavigationItemsRemapped = studNavigationItems.slice(0);

                studNavigationItemsRemapped.map(fac => {
                    fac.name = fac.nameUk;
                    fac.specialities.map(spec => {

                        spec.name = spec.nameUk;

                        spec.children = spec.semesters.map(sem => {
                            sem.name = `${sem.yearNumber} курс (${sem.semesterNumber} семестр)`;

                            sem.children = sem.academicGroups.map(acgroup => {
                                acgroup.name = `${acgroup.groupNumber} група`;

                                acgroup.route = {
                                    name: this.routeTo,
                                    params: {
                                        faculty: acgroup.faculty.id,
                                        speciality: acgroup.speciality.id,
                                        semester: acgroup.semester.id,
                                        group: acgroup.id,
                                    }
                                };
                                if (acgroup.id.toString() === this.$route.params.group) {
                                    acgroup.model = true;
                                }

                                return acgroup;
                            });

                            let someAcGroupActive = sem.children.find(ch => ch.model);

                            if (sem.id.toString() === this.$route.params.year && someAcGroupActive !== undefined) {
                                sem.model = true;
                            }

                            delete sem.academicGroups;

                            return sem;
                        });

                        let someSemesterActive = spec.children.find(ch => ch.model);

                        if (spec.id.toString() === this.$route.params.speciality && someSemesterActive !== undefined) {
                            spec.model = true;
                        }

                        delete spec.semesters;

                        return spec;
                    });

                    return fac;
                });

                return studNavigationItemsRemapped;
            },
        }
    }
</script>