<template>
    <div>
        <v-menu offset-y left>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="info">
                    {{ $auth.getCurrentUser() }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="logout()">
                    <v-list-item-title>{{ $t("toolbar.logOut") }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn v-if="!$auth.isAuthenticated()" @click="$auth.login()" color="warning">
            {{ $t("dashboard.loginButtonTextShort") }}
        </v-btn>
    </div>
</template>
<script>
import HttpService from "@/HttpService";

export default {
    methods: {
        logout() {
            HttpService.getInstance({}, {}, true)
                .post('/authorization/revoke', {token: this.$auth.getRefreshToken()})
                .then(() => {
                    this.$auth.logout();
                    this.$router.push({name: 'login'});
                });
        },
    }
}
</script>