export default {
    default: {
        apiEndpoint: "",
        dashboardLinks: {
            stepSuperAdmin: "stepSuperAdmin",
            stepAdmin: "stepAdmin",
            step: "step",
            modules: "modules",
        },
    },
    development: {
        apiEndpoint: "http://localhost:5080/api",
        apiMainEndpoint: "http://localhost:5000/api",
    },
    production: {
        apiEndpoint: "https://nbapi.pdmu.edu.ua/api",
        apiMainEndpoint: "https://ezapi.pdmu.edu.ua/api",
    }
}