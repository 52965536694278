<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-container grid-list-md>
                <navigation-cards/>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationCards from "../components/dashboard/NavigationCards";
    import {rolesEnum} from "@/constants/rolesEnum";

    export default {
        components: {
            NavigationCards,
        },
        mounted() {
            if (this.$auth.userHasAtLeastOneRole([rolesEnum.quizStep])) {
                this.$router.push({name: "studentQuizRoot"});
            }
        }
    }
</script>
