<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
            <v-flex xs12>
                <student-quiz-wrapper
                        api-get-question-student-overview="studentQuiz/getQuestionStudentOverview"
                        api-finish-quiz-attempt="studentQuiz/finishQuizAttempt"
                        api-finish-quiz-penalty-attempt="studentQuiz/finishQuizAttemptPenalty"
                        api-get-question-student="studentQuiz/getQuestionStudent"
                        api-save-question-answer="studentQuiz/saveQuestionAnswer"
                        
                        route-quiz-finish="studentQuizFinish"
                        route-quiz-home="studentQuizHome"
                        :route-quiz-additional-params="{}"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import StudentQuizWrapper from "../../components/studentQuiz/StudentQuizWrapper";
    
    export default {
        components: {
            StudentQuizWrapper 
        }
    }
</script>