<template>
    <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
            <form @submit="submitForm" novalidate="true">
                <v-toolbar dark color="info">
                    <v-toolbar-title>Встановлення номеру ПК</v-toolbar-title>
                    <v-spacer/>
                    <language-switcher/>
                </v-toolbar>
                <v-card-text>
                    <h3>Існуюча назва компʼютера: 
                    <v-progress-circular
                        v-if="loadingPcName"
                        indeterminate
                        size="20"
                        color="primary"
                    ></v-progress-circular> 
                    <span v-else>
                        {{existingPcName ? existingPcName : "-"}}
                    </span>
                    </h3>
                    <v-form>
                        <v-text-field
                                prepend-icon="person"
                                v-model="form.username"
                                :label="$t('login.login')"
                                type="text"
                                :error-messages="errors.username"
                        ></v-text-field>
                        <v-text-field
                                prepend-icon="lock"
                                v-model="form.password"
                                :label="$t('login.password')"
                                type="password"
                                :error-messages="errors.password"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.pcName"
                            label="Номер/Назва ПК"
                            type="text"
                            :error-messages="errors.pcName"
                        ></v-text-field>
                    </v-form>
                    <div>
                        <router-link to="code">{{$t('login.linkQuiz')}}</router-link>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                            color="primary" 
                            type="submit"
                            :disabled="loading"
                    >Відправити</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-flex>
</template>
<script>
    import {remapErrors} from "../helpers/errorsHelper";
    import HttpService from "../HttpService";
    import LanguageSwitcher from "../components/common/LanguageSwitcher";
    
    export default {
        components: {
            LanguageSwitcher,
        },
        data: () => {
            return {
                form: {
                    username: '',
                    password: '',
                    pcName: '',
                },
                errors: {
                    username: [],
                    password: [],
                    pcName: [],
                },
                loading: false,
                
                loadingPcName: false,
                existingPcName: '',
            };
        },
        mounted() {
            this.loadingPcName = true;
            HttpService.getInstance()
                .get(`authorization/getPcName/${this.$cookie.get('pcnumberid')}`)
                .then(resp => {
                    this.loadingPcName = false;
                    this.existingPcName = resp;
                })
                .catch(err => {
                    this.loadingPcName = false;
                });
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loading = true;

                
                const payload = {...this.form, ...{pcNumberId: this.$cookie.get('pcnumberid')}};
                HttpService.getInstance()
                    .post("authorization/setPcName", payload)
                    .then(resp => {
                        this.loading = false;

                        this.$cookie.set('pcnumberid', resp, 365);
                    })
                    .catch(err => {
                        this.loading = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    };
</script>