<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
            <v-flex xs12>
                <student-quiz-finish-wrapper/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import StudentQuizFinishWrapper from "../../components/studentQuiz/StudentQuizFinishWrapper";
    
    export default {
        components: {
            StudentQuizFinishWrapper 
        }
    }
</script>