<template>
    <v-flex>
        <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                :nudge-right="40"
                :return-value.sync="date"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-model="formattedDate"
                        :label="label"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                    v-model="date" 
                    @input="save"
                    :min="minDateLocal"
                    :max="maxDateLocal"
            ></v-date-picker>
        </v-menu>
    </v-flex>
</template>
<script>
    import {format, parse} from "date-fns";

    export default {
        data: () => ({
            date: null,
            menu: false,
            minDateLocal: undefined,
            maxDateLocal: undefined
        }),
        props: {
            value: {
                type: Date,
            },
            errorMessages: {
                type: Array,
            },
            label: {
                type: String,
                defaultsTo: "Please, chose a date"
            },
            minDate: {
              type: Date,
              defaultsTo: undefined,  
            },
            maxDate: {
                type: Date,
                defaultsTo: undefined,
            },
        },
        computed: {
            formattedDate: {
                get() {
                    if (this.date !== null && this.date !== undefined) {
                        return format(this.date, "DD.MM.YYYY");
                    }

                    return undefined;
                }
            }
        },
        mounted: function () {
            this.date = this.formatDate(this.value);
            this.minDateLocal = this.formatDate(this.minDate);
            this.maxDateLocal = this.formatDate(this.maxDate);
        },
        updated: function () {
            this.date = this.formatDate(this.value);
            this.minDateLocal = this.formatDate(this.minDate);
            this.maxDateLocal = this.formatDate(this.maxDate);
        },
        methods: {
            formatDate(date) {
                if (date !== null && date !== undefined) {
                   return format(date, "YYYY-MM-DD");
                }
            },
            save(date) {
                this.$emit("input", parse(date));
                this.$emit("change", parse(date));
                this.$refs.menu.save(date)
            },
        }
    }
</script>