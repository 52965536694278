<template>
    <v-toolbar-items>
        <v-menu transition="scale-transition" offset-y right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       v-on="on" 
                       text>
                    <img width="32px" :src="getActiveLanguage()"/>
                </v-btn>
            </template>
            <v-list right>
                <v-list-item v-for="language in languages" :key="language.language"
                             @click="setActiveLanguage(language.locale)">
                    <v-list-item-avatar size="30">
                        <img height="30" :src="language.image">
                    </v-list-item-avatar>
                    <v-list-tile-content>
                        <v-list-tile-title>{{ language.language }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-toolbar-items>
</template>
SnackDisplayer

<script>
    import enGb from "../../assets/images/flags/en-GB.png";
    import ukUa from "../../assets/images/flags/uk-UA.png";

    export default {
        data: () => ({
            languages: [
                {
                    locale: 'en-US',
                    language: 'English',
                    image: enGb,
                },
                {
                    locale: 'uk-UA',
                    language: 'Українська',
                    image: ukUa,
                }
            ],
            url: '../../assets/images/flags/uk-UA.png',
        }),
        created() {
            let cookieLocale = this.$cookie.get('locale');
            if (cookieLocale === null) {
                this.$cookie.set('locale', this.$i18n.locale, 7);
            }
            else {
                this.$i18n.locale = cookieLocale;
                this.$i18n.lang = this.capitalize(cookieLocale.substring(0, 2));
            }
        },
        methods: {
            setActiveLanguage(locale) {
                this.$i18n.locale = locale;
                this.$i18n.lang = this.capitalize(locale.substring(0, 2));
                this.$cookie.set('locale', locale, 7);
                location.reload();
            },
            getActiveLanguage() {
                return this.languages.find(l => l.locale === this.$i18n.locale).image;
            },
            capitalize(string) {
                return string && string[0].toUpperCase() + string.slice(1);
            }
        }
    }
</script>