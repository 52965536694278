import Vue from 'vue';
import config from "../app.config";
import {AppConfig} from "../config/configFactory";
import jwtDecode from "jwt-decode";
import intersectionWith from "lodash/intersectionWith";
import lodash from "lodash";

let configuration = AppConfig(config);

let auth = new Vue({
    created() {
    },
    computed: {
        bearerToken: {
            get: () => {
                return localStorage.getItem('bearer_token');
            },
            set: (token) => {
                localStorage.setItem('bearer_token', token);
            }
        },
        refreshToken: {
            get: () => {
                return localStorage.getItem('refresh_token');
            },
            set: (token) => {
                localStorage.setItem('refresh_token', token);
            }
        },
        authToken: {
            get: () => {
                return JSON.parse(localStorage.getItem('auth_token'));
            },
            set: (token) => {
                localStorage.setItem('auth_token', JSON.stringify(jwtDecode(token)));
            }
        },
    },
    methods: {
        login(token, refreshToken) {
            this.bearerToken = token;
            this.refreshToken = refreshToken;
            
            this.authToken = token;
        },
        logout() {
            localStorage.removeItem('bearer_token');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
        },    
        isAuthenticated() {
            const authToken = JSON.parse(localStorage.getItem('auth_token'));
            if (authToken === null) {
                return false;
            }

            return (new Date().getTime()/1000) < authToken.exp;
        },
        getCurrentUser() {
            const authToken = JSON.parse(localStorage.getItem('auth_token'));
            return (authToken !== null && authToken.unique_name);
        },
        getRoles() {
            const authToken = JSON.parse(localStorage.getItem('auth_token'));
            
            if (authToken !== null) {
                return authToken.role;
            }
            
            return [];
        },
        userHasAtLeastOneRole(rolesToCheck) {
            const roles = this.getRoles();

            const intersection = lodash.intersection(roles, rolesToCheck);

            return intersection.length > 0;
        },
        hasRoles(rolesStr) {
            let role = typeof rolesStr === "string" ? [rolesStr] : rolesStr;

            return intersectionWith(role, this.getRoles(), (a, b) => {
                return a === b;
            }).length > 0;
        },
        getBearerToken() {
            return localStorage.getItem('bearer_token');
        },
        getRefreshToken() {
            return localStorage.getItem('refresh_token');
        }
    }
});

export default {
    install: function (Vue) {
        Vue.prototype.$auth = auth;
    }
}