import routeGuard from "../routeGuards/routeGuard";
import Home from "../views/Home";
import Root from '../components/common/Root';
import NotFoundView from "../views/common/NotFoundView";

export default (router) => {
    return [
        {
            path: '/',
            component: Root,
            children: [
                {
                    path: '',
                    redirect: {name: 'dashboard'},
                },
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: Home,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: '/NotFound',
                    name: 'NotFound',
                    component: NotFoundView,
                },
            ],
        },
    ]
}

