import StudentQuizRootView from '../views/studentQuiz/StudentQuizRootView';
import StudentQuizHomeView from '../views/studentQuiz/StudentQuizHomeView';
import StudentQuizFinishView from '../views/studentQuiz/StudentQuizFinishView';

import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
    return [
        {
            path: '/studentQuiz',
            component: StudentQuizRootView,
            name: 'studentQuizRoot',
            redirect: {name: 'studentQuizHome', params: {questionNumber: 1}},
            children: [
                {
                    path: ':questionNumber?',
                    name: 'studentQuizHome',
                    component: StudentQuizHomeView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: '/studentQuizFinish',
                    name: 'studentQuizFinish',
                    component: StudentQuizFinishView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
            ],
        },
    ];
}