<template>
    <v-layout row wrap>
        <v-flex xs12>
            <v-card class="mb-2">
                <v-card-text>
                    <div class="title text-center">{{$t('studentQuiz.quizIsFinished')}}</div>
                    <div class="display-2 mt-5 text-center">{{quizFinish.percentage.toFixed(2)}}%</div>
                    <div class="text-center mt-2">
                        <v-chip color="primary" text-color="white">
                            <div class="display-1">{{quizFinish.traditionalMark}}</div>
                        </v-chip>
                    </div>
                    <div class="display-2 mt-2 text-center">{{quizFinish.correctQuestions}}/{{quizFinish.totalQuestions}}</div>
                    <div class="mt-3 text-center">
                        <v-btn 
                                color="red" 
                                dark 
                                large 
                                @click="logout()"
                        >
                            {{$t('studentQuiz.logoutFromSystem')}}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../HttpService";
    
    export default {
        data: () => ({
            quizFinish: {
                totalQuestions: 0,
                correctQuestions: 0,
                percentage: 0,
                traditionalMark: 0,
            },
        }),
        mounted() {
            HttpService.getInstance()
                .post(`studentQuiz/finishQuizAttempt`)
                .then(resp => {
                    this.quizFinish = resp;
                });

            setTimeout(() => {
                this.logout();
            }, 15000);
        },
        methods: {
            logout() {
                HttpService.getInstance()
                    .post('/authorization/revoke', {token: this.$auth.getRefreshToken()})
                    .then(() => {
                        this.$auth.logout();
                        this.$router.push({name: 'code'});
                    });
            },
        }
    }
</script>