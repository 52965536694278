<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="{item}">
            <tr>
                <td>
                    <div>{{item.student.surname}} {{item.student.name}} {{item.student.patronymic}}</div>
                    <div class="font-weight-thin">{{item.student.faculty.nameUk}}, {{item.student.speciality.nameUk}}</div>
                    <div class="font-weight-thin">{{item.student.semester.yearNumber}} курс</div>
                    <div class="font-weight-thin">{{item.student.academicGroup.groupNumber}} група</div>
                </td>
                <td>
                    <div><span class="font-weight-thin">Дисципліна: </span>{{item.subject.nameUk}}</div>
                    <div v-if="item.topic"><span class="font-weight-thin">Заняття: </span>{{item.topic.nameUk}}</div>
                    <div v-if="item.lecture"><span class="font-weight-thin">Лекція: </span>{{item.lecture.nameUk}}</div>
                </td>
                <td>
                    <div>{{formatLongDate(item.quizSetAt)}}</div>
                </td>
                
                <td>
                    <div>{{formatLongDate(item.quizStart)}}</div>
                    <div><span class="font-weight-thin">ІР: </span>{{item.quizStartIp ? item.quizStartIp : "-"}}</div>
                </td>
                
                <td>
                    <div>{{formatLongDate(item.quizEnd)}}</div>
                    <div><span class="font-weight-thin">ІР: </span>{{item.quizEndIp ? item.quizEndIp : "-"}}</div>
                </td>
                
                <td>
                    <div>
                        <span v-if="item.quizValid"><v-icon color="green">done</v-icon></span>
                        <span v-if="!item.quizValid"><v-icon color="red">close</v-icon></span>

                        <span v-if="item.quizIsPenalty">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                        <v-icon color="red" v-on="on">cancel</v-icon>
                                </template>
                                <span>Спроба була припинена</span>
                            </v-tooltip>
                        </span>
                        <span v-if="item.quizIsWarned">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                        <v-icon color="orange darken-2" v-on="on">warning</v-icon>
                                </template>
                                <span>Видано попередження</span>
                            </v-tooltip>
                        </span>
                    </div>
                </td>
                <td>
                    <div><span class="font-weight-thin">Результат (%): </span>{{item.quizResult ? item.quizResult : "-"}}</div>
                    <div><span class="font-weight-thin">Вірно: </span>{{item.quizResultCountCorrect ? item.quizResultCountCorrect : "-"}}</div>
                    <div><span class="font-weight-thin">Всього: </span>{{item.QuizResultCount ? item.QuizResultCount : "-"}}</div>
                </td>
                <td>{{item.quizAttemptCode}}</td>

                <td v-if="userIsAdmin()">
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import {formatLongDate} from "@/helpers/dateHelper";
    import {rolesEnum} from "@/constants/rolesEnum";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        mounted() {
            if (this.$auth.hasRoles(rolesEnum.admin)) {
                this.headers.push({text: 'Дії', value: 'actions', sortable: false});
            }
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            mapSpecialitiesToString(specialities) {
                return specialities.map(spec => spec.nameUk).join(", ");
            },
            userIsAdmin() {
                return this.$auth.hasRoles(rolesEnum.admin);
            }
        },
        data: () => ({
            formatLongDate,
            
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Студент', value: 'student', sortable: false,},
                {text: 'Дисципліна та тема', value: 'subject', sortable: false, width: "30%"},
                {text: 'Згенеровано', value: 'quizSetAt', sorting: "descending"},
                {text: 'Початок', value: 'quizStart'},
                {text: 'Кінець', value: 'quizEnd'},
                {text: 'Валідність', value: 'quizValid', sortable: false,},
                {text: 'Результат', value: 'quizResult', sortable: false,},
                {text: 'Код тестування', value: 'quizAttemptCode', sortable: false,},
            ],
        }),
    }
</script>