<template>
    <div>
        <students-grid
                :items="items"
                :total="total"
                :loading="loading"
        >
        </students-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import StudentsGrid from "@/components/admin/Students/StudentsGrid";

    export default {
        components: {
            StudentsGrid,
        },
        mounted() {
            this.getItemsWithRoute();
        },
        methods: {
            getItemsWithRoute() {
              this.getItems(this.$route.params.faculty, this.$route.params.speciality, this.$route.params.semester, this.$route.params.group);  
            },
            getItems(facultyId, specialityId, semesterId, groupId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentReworks/${facultyId}/${specialityId}/${semesterId}/${groupId}`)
                    .then(students => {
                        this.items = students.items.map((item, index) => {
                            item.index = index + 1;
                            
                            return item;
                        });
                        this.total = students.total;
                        this.loading = false;
                    })
            },
        },
        data: () => {
            return {
                items: [],
                total: null,

                loading: false,
            }
        }
    }
</script>