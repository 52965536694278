<template>
    <div>
        <v-data-table
                :hide-default-footer="true"
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                class="elevation-3"
        >
            <template v-slot:item="props">
                <tr :class="{
                    'yellow lighten-5': (props.item.countLectureAbsences > 0 && props.item.countAbsences === 0), 
                    'red lighten-5': (props.item.countAbsences > 0 || (props.item.countAbsences > 0 && props.item.countLectureAbsences > 0))
                }">
                    <td>{{ props.item.index }}</td>
                    <td class="text-xs-left">{{ props.item.surname }} {{ props.item.name }} {{props.item.patronymic }}</td>
                    <td class="text-xs-left">{{ formatShortDate(props.item.birthday) }}</td>
                    <td class="text-xs-left">
                        <div v-if="props.item.gender === 0">Чоловіча</div>
                        <div v-if="props.item.gender === 1">Жіноча</div>
                    </td>
                    <td class="text-xs-left">
                        <v-btn text class="mx-0" @click="openAdmissionsByStudent(props.item)">
                            <v-icon color="primary">assignment</v-icon>
                            <span class="ml-2">{{props.item.countAbsences}}</span>
                        </v-btn>
                    </td>
                    <td class="text-xs-left">
                        <v-btn text class="mx-0" @click="openLectureAdmissionsByStudent(props.item)">
                            <v-icon color="yellow darken-4">assignment</v-icon>
                            <span class="ml-2">{{props.item.countLectureAbsences}}</span>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>


        <v-dialog v-model="reworksPerStudentDialog"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable>
            <reworks-list-per-student
                v-if="reworksPerStudentDialog"
                @shouldCloseAddEdit="reworksPerStudentDialog = false"
                :student="selectedStudent"
            ></reworks-list-per-student>
        </v-dialog>

        <v-dialog v-model="reworksLecturesPerStudentDialog"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable>
            <reworks-lectures-list-per-student
                v-if="reworksLecturesPerStudentDialog"
                @shouldCloseAddEdit="reworksLecturesPerStudentDialog = false"
                :student="selectedStudent"
            ></reworks-lectures-list-per-student>
        </v-dialog>
    </div>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import ReworksListPerStudent from "@/components/admin/Students/ReworksListPerStudent";
    import ReworksLecturesListPerStudent from "@/components/admin/Students/ReworksLecturesListPerStudent";

    export default {
        components: {
            ReworksListPerStudent,
            ReworksLecturesListPerStudent,
        },
        props: {
            items: {
                type: Array,
            },
            selectedItems: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        computed: {
            selectedItemsComputed: {
                get() { return this.selectedItems},
                set(val) { this.$emit('update:selectedItems', val)}
            }
        },
        data: () => ({
            formatShortDate,
            selectedStudent: null,
            reworksPerStudentDialog: false,
            reworksLecturesPerStudentDialog: false,
            headers: [
                {text: '№', value: 'index', sortable: false},
                {text: 'Прізвище, ім\'я, по-батькові', value: 'surname', sortable: false},
                {text: 'Дата народження', value: 'birthday', sortable: false},
                {text: 'Стать', value: 'gender', sortable: false},
                {text: 'Кількість "нб" занять', value: 'countAbsences', sortable: false},
                {text: 'Кількість "нб" лекцій', value: 'countLectureAbsences', sortable: false},
            ],
        }),
        methods: {
            openAdmissionsByStudent(student) {
                this.selectedStudent = student;
                this.reworksPerStudentDialog = true;
            },
            openLectureAdmissionsByStudent(student) {
                this.selectedStudent = student;
                this.reworksLecturesPerStudentDialog = true;
            }
        }
    }
</script>