import { render, staticRenderFns } from "./StudentQuizFinishView.vue?vue&type=template&id=7bc33955"
import script from "./StudentQuizFinishView.vue?vue&type=script&lang=js"
export * from "./StudentQuizFinishView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports