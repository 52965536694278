<template>
    <div>
        <div class="d-flex justify-space-between align-center mb-4">
            <div class="flex-grow-1"></div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        style="max-width: 300px;"
                        v-model="dateRangeText"
                        label="Фільтрувати за датами"
                        prepend-icon="calendar_month"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    no-title
                    scrollable
                    range
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Відміна
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                    >
                        ОК
                    </v-btn>
                </v-date-picker>
            </v-menu>
            <v-select
                style="max-width: 250px"
                class="ml-2"
                :items="filterItems"
                v-model="dateFilter"
                @change="onFilter($event)"
                item-text="name"
                item-value="id"
                label="Фільтри"
                hide-details
                solo
            ></v-select>
            <v-btn class="ml-2"
                   @click="clearFilter()" text>Очистити
            </v-btn>
            <v-btn class="ml-2"
                   @click="search()" color="primary">Пошук
            </v-btn>
        </div>
        
        <quiz-list-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @deleteItem="deleteItem"
        >
        </quiz-list-grid>

        <v-dialog v-model="dialogRemove" max-width="400">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>
    </div>
</template>
<script>
    import moment from 'moment';
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import QuizListGrid from "@/components/admin/QuizList/QuizListGrid";
    import DeleteConfirm from "@/components/admin/QuizList/DeleteConfirm";
    
    export default {
        components: {
            QuizListGrid,
            DeleteConfirm,
        },
        mounted() {
            this.timer = setInterval(( )=> this.search(), 10000);
        },
        beforeDestroy() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },
        methods: {
            getItems(queryParam, dateFrom, dateTo) {
                this.loading = true;

                if (dateFrom && dateTo) {
                    queryParam = `${queryParam}
                    &dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }
                
                HttpService.getInstance()
                    .get(`listQuiz/getAll?${queryParam}`)
                    .then(items => {
                        this.items = items.items;
                        this.total = items.total;
                        this.loading = false;
                    })
            },
            search() {
                this.getItems(objectQueryStringify(this.pagination), this.dates[0], this.dates[1]);
            },
            onFilter(evt) {
                switch(evt) {
                    case '7days':
                        this.dates = [
                            moment().subtract(7, 'days').format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD"),
                        ];
                        
                        break;
                        
                    case '1week':
                        this.dates = [
                            moment().startOf('week').format("YYYY-MM-DD"),
                            moment().endOf('week').format("YYYY-MM-DD"),
                        ];
                        break;
                    case '2week':
                        this.dates = [
                            moment().subtract(2, 'weeks').startOf('week').format("YYYY-MM-DD"),
                            moment().endOf('week').format("YYYY-MM-DD"),
                        ];
                        break;

                    case '1month':
                        this.dates = [
                            moment().startOf('month').format("YYYY-MM-DD"),
                            moment().endOf('month').format("YYYY-MM-DD"),
                        ];
                        break;
                    case '2month':
                        this.dates = [
                            moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD"),
                            moment().endOf('month').format("YYYY-MM-DD"),
                        ];
                        break;
                    case '3month':
                        this.dates = [
                            moment().subtract(3, 'months').startOf('month').format("YYYY-MM-DD"),
                            moment().endOf('month').format("YYYY-MM-DD"),
                        ];
                        break;
                }
                
                this.search();
            },
            clearFilter() {
                this.dates = [];
                this.dateFilter = null;
                this.getItems(objectQueryStringify(this.pagination));
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        computed: {
            dateRangeText () {
                return this.dates.map(d => moment(d).format("DD.MM.YYYY")).join(' ~ ')
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },

                timer: null,
                
                dates: [],
                menu: false,

                dateFilter: null,
                filterItems: [
                    {
                        id: '7days',
                        name: 'Останні 7 днів',
                    },
                    {
                        id: '1week',
                        name: 'Останній 1 тиждень',
                    },
                    {
                        id: '2week',
                        name: 'Останні 2 тижня',
                    },
                    {
                        id: '1month',
                        name: 'Останній 1 місяць',
                    },
                    {
                        id: '2month',
                        name: 'Останні 2 місяці',
                    },
                    {
                        id: '3month',
                        name: 'Останні 3 місяці',
                    },
                ],
            }
        }
    }
</script>