<template>
    <v-card tile>
        <v-toolbar dark color="primary">
            <v-btn icon
                   dark
                   tabs
                   @click.native="$emit('shouldCloseAddEdit')"
                   class="ml-2"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Пропуски для {{student.surname}} {{student.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container grid-list-md style="height: 100%; overflow-x: auto;">
            <v-card class="mb-3">
                <v-card-title class="d-flex justify-center">{{student.surname}} {{student.name}} {{student.patronymic}}</v-card-title>
                <v-card-text class="d-flex justify-center">
                   <div>
                       <h4><span class="font-weight-light">Факультет:</span> {{student.faculty.nameUk}}</h4>
                       <h4><span class="font-weight-light">Спеціальність:</span> {{student.speciality.nameUk}}</h4>
                       <h4><span class="font-weight-light">Курс:</span> {{student.semester.yearNumber}}</h4>
                       <h4><span class="font-weight-light">Академічна група:</span> {{student.academicGroup.groupNumber}} група</h4>
                       <h4><span class="font-weight-light">Дата народження:</span> {{formatShortDate(student.birthday)}}</h4>
                   </div>
                    <div>
                        <img v-if="student.photoName" width="600" alt="" :src="student.photoName"/>
                    </div>
                </v-card-text>
            </v-card>
            <v-flex xs12>
                <v-data-table
                        :headers="headers"
                        :items="reworkItems"
                        :loading="loading"
                        :server-items-length="reworkItems.length"
                        class="elevation-3"
                        hide-default-footer
                >
                    <template v-slot:item.actions="props">
                        <v-tooltip v-if="props.item.couldEmitCode" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="getCode(props.item)" v-on="on" icon class="mx-0">
                                    <v-icon large color="primary">touch_app</v-icon>
                                </v-btn>
                            </template>
                            <span>Видати код тестування</span>
                        </v-tooltip>
                        <v-tooltip v-if="!props.item.couldEmitCode" bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" large color="red">cancel</v-icon>
                            </template>
                            <span v-if="props.item.alreadyTriedTopicToday">Для даної теми вже була спроба складання</span>
                            <span v-if="props.item.reachedTopicLimitToday">Досягнуто ліміт складань на сьогодні</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.payable="props">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-if="props.item.isSubjectPayable" color="red" v-on="on">attach_money</v-icon>
                            </template>
                            <span>Оплачувані допуски</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-if="!props.item.isSubjectPayable" color="green" v-on="on">strikethrough_s</v-icon>
                            </template>
                            <span>НЕоплачувані допуски</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.reworkPrice="props">
                        {{props.item.reworkPrice}}
                    </template>
                    <template v-slot:item.subject="props">
                        {{ props.item.subject.nameUk }}
                    </template>
                    <template v-slot:item.topic="props">
                        {{ `${props.item.topic.orderNumber}. ${props.item.topic.nameUk}` }}
                    </template>
                    <template v-slot:item.academicHours="props">
                        {{props.item.topic.academicHours}}
                    </template>
                    <template v-slot:item.passDate="props">
                        {{ formatShortDate(props.item.classDate) }}
                    </template> 
                    <template v-slot:item.admissionEnd="props">
                        {{ formatShortDate(props.item.topicAbsence.admissionEnd) }}
                    </template>
                </v-data-table>
            </v-flex>
        </v-container>

        <v-dialog v-model="confirmCode" max-width="700" scrollable>
            <code-confirm
                v-if="confirmCode"
                @shouldCloseSaveConfirm="clearCode()"
                @codeEmited="getItems"
                :reworkItem="selectedReworkItem"
                :is-lecture="false"
            >
            </code-confirm>
        </v-dialog>
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../HttpService";
    import DatePicker from "../../../components/common/DatePicker";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import isEmpty from "lodash/isEmpty";
    import isArray from "lodash/isArray";
    import CodeConfirm from "@/components/admin/Students/CodeConfirm";

    export default {
        components: {
            DatePicker,
            CodeConfirm,
        },
        data: () => ({
            formatShortDate,
            loading: false,
            loadingSave: false,
            reworkItems: [],

            itemsAdmissionCanBeEmited: [],

            headers: [
                {text: 'Дії', value: 'actions', sortable: false},
                {text: 'Оплачуваний', value: 'payable', sortable: false},
                {text: 'Ціна відпрацювання', value: 'reworkPrice', sortable: false},
                {text: 'Дисципліна', value: 'subject', sortable: false},
                {text: 'Тема', value: 'topic', sortable: false},
                {text: 'К-сть годин', value: 'academicHours', sortable: false},
                {text: 'Дата пропуску', value: 'passDate', sortable: false},
                {text: 'Допуск до', value: 'admissionEnd', sortable: false},
            ],
            isEmpty,

            confirmCode: false,
            selectedReworkItem: null,
        }),
        props: {
            student: Object,
        },
        mounted() {
            this.getItems();
        },
        methods: {
            clearCode() {
                this.confirmCode = false;
                this.selectedReworkItem = null;
                this.getItems();
            },
            getCode(item) {
                this.confirmCode = true;
                this.selectedReworkItem = item;
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentReworks/reworksList/${this.student.id}`)
                    .then(resp => {
                        this.reworkItems = resp;
                        this.loading = false;
                    });
            },
        },
    }
</script>