export default {
    navigation: {
        superAdminReworks: {
            title: "SuperAdmin EzNb",
            description: "SuperAdmin EzNb"
        },
        adminReworks: {
            title: "Admin EzNb",
            description: "Administration of testing"
        },
    },
    toolbar: {
        logOut: "Вийти",
        settings: "Налаштування"
    },
    loginKrok: {
        title: "Login to PSMU-reworks",
        code: "Please insert the given quiz code",
        button: "Start quiz",
        link: "Service login"
    },
    login: {
        title: "Login to PSMU",
        login: "Username",
        password: "Password",
        button: "Log-in",
        linkQuiz: "Nb-quiz"
    },
    
    studentKrok: {
        electronicRegister: "Electronic register",
    },
    studentQuiz: {
        remaining: "Remaining",
        automaticSwitch: "Switch questions automatically",
        questionNo: "Question #",
        previousQuestion: "Previous question",
        nextQuestion: "Next question",
        answeredQuestion: "This question was already answered",
        finishQuiz: "Finish quiz",
        quizIsFinished: "Quiz is finished",
        logoutFromSystem: "Log-out from quiz"
    },
}