export const En = {
    short: "DD/MM/YYYY",
    long: "DD/MM/YYYY HH:mm",
};

export const Uk = {
    short: "DD.MM.YYYY",
    long: "DD.MM.YYYY HH:mm",
};

export default {
    "en-US": En,
    "uk-UA": Uk,
}