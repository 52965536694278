import axios from "axios";
import ErrorHandlingService from "./ErrorHandlingService";
import config from "./app.config";
import {AppConfig} from "./config/configFactory";
import store from "./store";
import {vueInstance} from "./main";
import extend from "lodash/extend";

const configuration = AppConfig(config);

export default class AxiosService {
    static instance = null;

    static isRefreshing = false;
    static refreshSubscribers = [];

    static getInstance = (postAdditionalHeaders = {}, getAdditionalHeaders = {}, withoutSnackMessage = false) => {
        AxiosService.instance = axios.create({
            baseURL: configuration.apiEndpoint,
            validateStatus: status => status < 500,
        });

        AxiosService.instance.interceptors.request.use(config => {
            let bearerToken = vueInstance.$auth.getBearerToken();

            if (bearerToken !== undefined) {
                config.headers.Authorization = `Bearer ${bearerToken}`;
            }

            const getHeaders = {
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Content-Type": "application/json"
            };

            const postHeaders = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            config.headers.get = extend({}, getHeaders, getAdditionalHeaders);
            config.headers.post = extend({}, postHeaders, postAdditionalHeaders);

            store.commit("loadingState/setLoading");

            return config;
        });

        AxiosService.instance.interceptors.response.use(response => {
            const {config, status} = response;
            const originalRequest = config;

            if (status === 401) {
                if (!this.isRefreshing) {
                    this.isRefreshing = true;
                    store.commit("loadingState/setLoading");

                    AxiosService.refreshAccessToken()
                        .then(response => {
                            const {refreshToken, token} = response.data;
                            vueInstance.$auth.refreshToken = refreshToken;
                            vueInstance.$auth.bearerToken = token;
                            vueInstance.$auth.authToken = token;

                            this.isRefreshing = false;
                            AxiosService.onRefreshed(token);

                            store.commit("loadingState/setLoaded");
                        });
                }

                return new Promise((resolve, reject) => {
                    AxiosService.subscribeTokenRefresh(token => {
                        const bearerToken = vueInstance.$auth.getBearerToken();
                        // replace the expired token and retry
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;

                        store.commit("loadingState/setLoaded");

                        AxiosService.refreshSubscribers = [];

                        resolve(axios(originalRequest));
                    });
                });
            }

            store.commit("loadingState/setLoaded");

            return ErrorHandlingService.prodcessReponse(response, withoutSnackMessage);
        });

        return AxiosService.instance;
    };

    static refreshAccessToken() {
        let refreshToken = vueInstance.$auth.getRefreshToken();

        return axios.post(`${configuration.apiEndpoint}/authorization/refresh`, {token: refreshToken});
    }

    static subscribeTokenRefresh(cb) {
        this.refreshSubscribers.push(cb);
    }

    static onRefreshed(token) {
        this.refreshSubscribers.map(cb => cb(token));
    }
}