export const objectQueryStringify = (params) => {
    let paramsRemapped = {
        sortDirection: params.descending === null ? null : (!params.descending ? 'asc' : 'desc'),
        sortBy: params.sortBy,
        pageNumber: params.page,
        itemsPerPage: params.itemsPerPage,
        filter: (params.filter !== undefined) ? JSON.stringify(params.filter) : JSON.stringify([]),
    };
    
    return Object.keys(paramsRemapped).map(key => key + '=' + paramsRemapped[key]).join('&');
};