import Vue from 'vue';
import Router from 'vue-router';
import Auth from './components/common/Auth';
import Login from './views/Login';
import Code from './views/Code';
import dashoboardRoutes from "./routes/dashoboardRoutes";
import adminRoutes from "./routes/adminRoutes";
import studentQuizRoutes from "./routes/studentQuizRoutes";
import PcNumber from "@/views/PcNumber";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: Auth,
            children: [
                {
                    path: 'code',
                    name: 'code',
                    component: Code,
                },
                {
                    path: 'pcNumber301020',
                    name: 'pcNumber',
                    component: PcNumber,
                },
                {
                    path: 'login2389456874',
                    name: 'login',
                    component: Login,
                },
            ]
        },
        
    ],
});

router.addRoutes(dashoboardRoutes(router));
router.addRoutes(adminRoutes(router));
router.addRoutes(studentQuizRoutes(router));

export default router;
