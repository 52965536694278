<template>
    <div>
        <v-progress-linear
                v-if="loadingState > 0"
                height="3"
                style="margin: 0; z-index: 999; position: absolute"
                :indeterminate="true"
                color="info"
        ></v-progress-linear>

        <v-toolbar
                :color="color"
                dark
                app
                height="50px"
                :clipped-left="$vuetify.breakpoint.mdAndUp"
                fixed
        >
            <v-toolbar-title style="width: 250px" class="ml-0 pl-3 cursor-pointer" @click="$router.push('/')">
                <span class="hidden-sm-and-down">ePlato Reworks</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <language-switcher/>
            <v-spacer></v-spacer>

            <v-toolbar-title>{{getTopNavigationTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn id="v-step-0"
                   :class="{'invisible': !isRouteWithAdditionalNav() || !selectedNavigationItem.groupNumber}" rounded
                   @click.stop="$emit('toggleDrawerAdditional')" color="orange darken-1">
                {{selectedNavigationItem.facultyName}} &bull; {{selectedNavigationItem.yearNumber}} курс &bull;
                {{selectedNavigationItem.groupNumber}} група
            </v-btn>


            <v-menu v-if="$auth.isAuthenticated()" offset-y left>
                <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       v-on="on" 
                       color="info">
                    {{$auth.getCurrentUser()}}
                </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="logout()">
                        <v-list-item-title>Log off</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn v-if="!$auth.isAuthenticated()" @click="$auth.login()" color="warning">
                Log in
            </v-btn>
        </v-toolbar>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import HttpService from "../../../HttpService";
    import LanguageSwitcher from "../../../components/common/LanguageSwitcher";

    export default {
        name: 'group-navigation-tour',
        props: {
            color: {
                type: String,
                default: 'info'
            }
        },
        components: {
            LanguageSwitcher
        },
        data() {
            return {
                tourOptions: {
                    labels: {
                        buttonStop: "Зрозуміло"
                    }
                },
                tourCallbacks: {
                    onStop: this.onStopCallback,
                },
                steps: [
                    {
                        target: '#v-step-0',
                        content: `Щоб змінити групу клікніть тут`
                    },
                ]
            };
        },
        watch: {
            "selectedNavigationItem.groupNumber": function (newGroupNumber, oldGroupNumber) {
                if (oldGroupNumber !== newGroupNumber) {
                    const teacherGroupSelectTour = this.$cookie.get("teacherGroupSelectTour");
                    if (!teacherGroupSelectTour) {
                        this.$tours['groupNavigationTour'].start();
                    }
                }
            }
        },
        computed: {
            ...mapGetters(['selectedNavigationItem', 'getTopNavigationTitle']),
            loadingState() {
                return this.$store.getters['loadingState/loadingState'];
            },
        },
        methods: {
            onStopCallback() {
                this.$cookie.set("teacherGroupSelectTour", true, 30);
            },
            isRouteWithAdditionalNav() {
                return true;
                // return this.$route.params.group !== undefined;
            },
            logout() {
                HttpService.getInstance()
                    .post('/authorization/revoke', {token: this.$auth.getRefreshToken()})
                    .then(() => {
                        this.$auth.logout();
                        this.$router.push({name: 'code'});
                    });
            },
            goToMainDashboard() {
                window.location.href = this.$config.mainDashboardUrl;
            }
            
        },
    }
</script>