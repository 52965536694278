<template>
    <div>
        <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12>
                    <div class="headline">
                        <v-icon>keyboard_backspace</v-icon>
                        Виберіть пункт меню
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "HomeView"
}
</script>

<style scoped>

</style>