export default {
    navigation: {
        superAdminReworks: {
            title: "СуперАдмін ЕжНб",
            description: "СуперАдмін ЕжНб"
        },
        adminReworks: {
            title: "Адмін ЕжНб",
            description: "Адміністрування тестування"
        },
    },
    toolbar: {
        logOut: "Logout",
        settings: "Settings"
    },
    loginKrok: {
        title: "Вхід до ПДМУ-Нб",
        code: "Введіть наданий Вам код нижче",
        button: "Розпочати тестування",
        link: "Службовий вхід"
    },
    login: {
        title: "Вхід до ПДМУ",
        login: "Логін",
        password: "Пароль",
        button: "Увійти",
        linkQuiz: "Нб-тестування"
    },
    studentKrok: {
        electronicRegister: "Електронний журнал",
    },
    studentQuiz: {
        remaining: "Залишилося",
        automaticSwitch: "Автоматично переключати запитання",
        questionNo: "Питання №",
        previousQuestion: "Попереднє запитання",
        nextQuestion: "Наступне запитання",
        answeredQuestion: "На дане питання вже була дана відповідь",
        finishQuiz: "Завершити тестування",
        quizIsFinished: "Тестування завершено",
        logoutFromSystem: "Вийти зі системи"
    },
}