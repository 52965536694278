<template>
    <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
            <form @submit="submitForm" novalidate="true">
                <v-toolbar dark color="info">
                    <v-toolbar-title>{{$t('login.title')}}</v-toolbar-title>
                    <v-spacer/>
                    <language-switcher/>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-text-field
                                prepend-icon="person"
                                v-model="form.username"
                                :label="$t('login.login')"
                                type="text"
                                :error-messages="errors.username"
                        ></v-text-field>
                        <v-text-field
                                prepend-icon="lock"
                                v-model="form.password"
                                :label="$t('login.password')"
                                type="password"
                                :error-messages="errors.password"
                        ></v-text-field>
                    </v-form>
                    <div>
                        <router-link to="code">{{$t('login.linkQuiz')}}</router-link>
                    </div>
                    <div>
                        <a href="https://step.pdmu.edu.ua">ePlato Step</a>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                            color="primary" 
                            type="submit"
                            :disabled="loading"
                    >{{$t('login.button')}}</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-flex>
</template>
<script>
    import {remapErrors} from "../helpers/errorsHelper";
    import HttpService from "../HttpService";
    import LanguageSwitcher from "../components/common/LanguageSwitcher";
    
    export default {
        components: {
            LanguageSwitcher,
        },
        data: () => {
            return {
                form: {
                    username: '',
                    password: '',
                },
                errors: {
                    username: [],
                    password: [],
                },
                loading: false,
            };
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loading = true;

                HttpService.getInstance()
                    .post("authorization/login", this.form)
                    .then(resp => {
                        this.loading = false;
                        
                        this.$auth.login(resp.token, resp.refreshToken);
                        
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(err => {
                        this.loading = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    };
</script>