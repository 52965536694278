<template>
    <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
            <form @submit="submitForm" novalidate="true">
                <v-toolbar dark color="red darken-1">
                    <v-toolbar-title>{{ $t('loginKrok.title') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <language-switcher/>
                </v-toolbar>
                <v-card-text>
                    <v-layout align-center justify-center>
                        <v-progress-circular size="125" v-if="loading" color="primary" indeterminate></v-progress-circular>
                    </v-layout>
                    <v-form v-if="!loading">
                        <div class="mb-4 body-2">{{ $t('loginKrok.code') }}</div>
                        <v-otp-input
                            class="mb-4"
                            v-model="form.code"
                            length="6"
                            @finish="submitForm"
                        ></v-otp-input>
                    </v-form>
                </v-card-text>
            </form>
        </v-card>
        <Keypress key-event="keyup" :multiple-keys="multiple" @success="successPcNumberCode" />
    </v-flex>
</template>
<script>
import {remapErrors} from "../helpers/errorsHelper";
import HttpService from "../HttpService";
import LanguageSwitcher from "../components/common/LanguageSwitcher";

export default {
    data: () => {
        return {
            form: {
                code: '',
            },
            loading: false,
            quizAdminCode: null,

            multiple: [
                {
                    keyCode: 49, // 1
                    modifiers: ['ctrlKey', 'shiftKey'],
                    preventDefault: true,
                },
            ],

            timer: null,
        };
    },
    components: {
        LanguageSwitcher,
        Keypress: () => import('vue-keypress')
    },
    mounted() {
        this.timer = setInterval(() => this.keyCombination = "", 5000);

        this.loading = true;
        HttpService.getInstance()
            .get("authorization/authCode")
            .then(resp => {
                this.loading = false;
                this.quizAdminCode = resp;
            });
    },
    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    },
    methods: {
        submitForm() {
            this.loading = true;
            
            if (this.form.code.toString() === this.quizAdminCode.toString()) {
                this.$router.push({name: "login"}).catch(err => {});
                
                return;
            }

            const pcNumberId = this.$cookie.get('pcnumberid');
            
            console.log("pcNumberId", pcNumberId);
            const payload = {...this.form, pcNumberId};

            HttpService.getInstance()
                .post("authorization/loginByQuizCode", payload)
                .then(resp => {
                    this.loading = false;

                    this.$auth.login(resp.token, resp.refreshToken);

                    this.$router.push({name: 'dashboard'});
                })
                .catch(err => {
                    this.loading = false;
                    this.form.code = null;
                    if (err.errors) {
                        this.errors = remapErrors(err.errors);
                    }
                });
        },
        successPcNumberCode() {
           this.$router.push({name: "pcNumber"}).catch(err => {});
        }
    }
};
</script>