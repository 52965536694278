<template>
    <div>
        <v-layout row wrap>
            <v-flex xs3>
                <v-card class="mb-2">
                    <v-card-text>
                        <div class="title">
                            <countdown
                                    v-if="countdown !== 0"
                                    emit-events
                                    @end="onQuizEnd()"
                                    :time="countdown"
                            >
                                <template v-slot="props">
                                    {{$t('studentQuiz.remaining')}}：{{ props.minutes }}:{{ props.seconds }}
                                </template>
                            </countdown>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card class="questions-card">
                    <v-card-text class="px-2 questions-wrapper">
                        <rework-quiz-buttons
                                :buttons-count="questionOverview.questionCount"
                                :questions-overview="questionOverview.quizQuestionOverviewItems"
                                
                                :route-quiz-home="routeQuizHome"
                                :route-quiz-additional-params="routeQuizAdditionalParams"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs9>
                <student-quiz-question
                        :question="activeQuestion"
                        :question-count="questionOverview.questionCount"
                        :questions-answered="questionsAnswered"
                        :was-answered="activeQuestionWasAnswered"
                        :loading="loading"
                        @selectAnswer="activeQuestionWasAnswered = true"
                        
                        :api-finish-quiz-attempt="ApiFinishQuizAttempt"
                        :route-quiz-finish="routeQuizFinish"
                        :route-quiz-home="routeQuizHome"
                        :route-quiz-additional-params="routeQuizAdditionalParams"
                        :route-quiz-finish-additional-params="routeQuizFinishAdditionalParams"
                        :api-save-question-answer="ApiSaveQuestionAnswer"
                />
            </v-flex>
        </v-layout>

        <v-dialog v-model="dialogWarning" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Попередження</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <div class="mb-4">
                            <h3 class="mb-2">Адміністратором тестування Вам видано попередження!</h3>
                            <v-alert type="warning">
                                У випадку якщо Ви продовжите порушувати регламент тестування, Ваше тестування буде
                                примусово завершене, оцінена як 0%, а спроба перескладання буде змарнована. Просимо Вас
                                поважати регламент!
                            </v-alert>
                        </div>
                        <hr>
                        <div class="mt-4">
                            <h3 class="mb-2">The administrator issued you a warning!</h3>
                            <v-alert type="warning">
                                If you continue to violate the testing regulations, your test will be forcibly terminated, 
                                graded as 0%, and the rework attempt will be wasted. Please respect the regulations!
                            </v-alert>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="dialogWarning = false"
                    >Зрозуміло</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPenalty" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Покарання</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <div class="mb-4">
                            <h3 class="mb-2">Адміністратором тестування було припинено тестування!</h3>
                            <v-alert type="error">
                                Ви грубо порушили регламент тестування, Ваше тестування було
                                примусово завершене, оцінена як 0%, а спроба перескладання змарнована.
                            </v-alert>
                        </div>
                        <hr>
                        <div class="mb-4">
                            <h3 class="mb-2">The test was stopped by the test administrator!</h3>
                            <v-alert type="error">
                                You grossly violated the testing regulations, your testing was
                                forced complete, rated as 0%, and the rework attempt was wasted.
                            </v-alert>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="onPenaltyClose"
                    >Зрозуміло</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../HttpService";
    import ReworkQuizButtons from "./ReworkQuizButtons";
    import StudentQuizQuestion from "./StudentQuizQuestion";
    import moment from 'moment';
    import store from "../../store";

    export default {
        components: {
            ReworkQuizButtons,
            StudentQuizQuestion,
        },
        data: () => ({
            activeQuestion: {id: 0, question: '', quizQuestionAnswers: []},
            activeQuestionWasAnswered: false,
            questionOverview: {
                questionCount: 0,
                quizEndTime: null,
                quizQuestionOverviewItems: [],
            },
            questionsAnswered: 0,
            countdown: 0,
            loading: false,
            
            shownWarn: false,

            dialogWarning: false,
            dialogPenalty: false,
        }),
        props: {
            ApiGetQuestionStudentOverview: String,
            ApiFinishQuizAttempt: String,
            ApiFinishQuizPenaltyAttempt: String,
            ApiGetQuestionStudent: String,
            ApiSaveQuestionAnswer: String,

            routeQuizFinish: String,
            routeQuizFinishAdditionalParams: Object,

            routeQuizHome: String,
            routeQuizAdditionalParams: Object,
        },
        mounted() {
            this.getQuestion(this.$route.params.questionNumber);
            this.getQuizOverview();
        },
        methods: {
            onQuizEnd() {
                if (this.questionOverview.quizEndTime === null) {
                    return;
                }
                
                const quizEndTimeParsed = moment(this.questionOverview.quizEndTime, moment.ISO_8601).utc();
                
                console.log("quizEndTimeParsed", quizEndTimeParsed);
                console.log("moment.utc()", moment.utc());

                if (quizEndTimeParsed.diff(moment.utc(),'seconds') < 1) {
                    this.finishQuiz();
                }
            },
            finishQuiz() {
                store.commit("snackMessages/set", {message: "Час для тестування вийшов", color: 'error'});

                HttpService.getInstance()
                    .post(this.ApiFinishQuizAttempt)
                    .then(resp => {
                        this.$router.push({
                            name: 'studentQuizFinish',
                        });
                    });
            },
            finishQuizPenalty() {
                HttpService.getInstance()
                    .post(this.ApiFinishQuizPenaltyAttempt)
                    .then(resp => {
                        this.$router.push({
                            name: 'studentQuizFinish',
                        });
                    });
            },
            getQuestion(questionNumber) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`${this.ApiGetQuestionStudent}/${questionNumber}`)
                    .then(resp => {
                        this.activeQuestion = resp;
                        this.activeQuestionWasAnswered = false;

                        this.loading = false;
                    });
            },
            getQuizOverview() {
                this.loading = true;

                HttpService.getInstance()
                    .get(this.ApiGetQuestionStudentOverview)
                    .then(resp => {
                        this.questionOverview = resp;

                        this.questionsAnswered = resp.quizQuestionOverviewItems.filter(s => s.isAnswered).length;

                        const quizEndTimeParsed = moment(resp.quizEndTime.replace("Z", ""), moment.ISO_8601).utc();
                        
                        this.countdown = quizEndTimeParsed.diff(moment.utc());

                        this.loading = false;
                        
                        if (!this.shownWarn && this.questionOverview.quizIsWarned) {
                            this.shownWarn = true;
                            
                            this.dialogWarning = true;
                        }

                        if (this.questionOverview.quizIsPenalty) {
                            this.dialogPenalty = true;

                            setTimeout(() => {
                                this.finishQuizPenalty();
                            }, 10000);
                        }
                    })
                    .catch(err => {
                        if (err.isExpiredQuiz) {
                            this.finishQuiz();
                        }
                    });
            },
            onPenaltyClose() {
                this.dialogPenalty = false;

                this.finishQuizPenalty();
            }
        },
        watch: {
            "$route.params.questionNumber": function (questionNumberNew, questionNumberOld) {
                if (questionNumberNew !== questionNumberOld) {
                    this.getQuestion(questionNumberNew);
                    this.getQuizOverview();
                }
            }
        },
    }
</script>
<style>
    .questions-card {
        height: 70vh;
    }

    .questions-wrapper {
        height: 100%;
        overflow-y: auto;
    }
</style>