<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <quiz-list-index :key="$route.fullPath"/>
        </v-layout>
    </v-container>
</template>
<script>

    import QuizListIndex from "@/components/admin/QuizList/QuizListIndex";

    export default {
        components: {
            QuizListIndex,
        },
        data: () => {
            return {
                studNavigationItems: [],
                isSelectedSemester: false,
                loading: false, 
            };
        },
    }
</script>