import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuex from 'vuex'
import VueCookie from "vue-cookie";
import vuetify from "./plugins/vuetify";
import AuthJwt from './auth/auth-jwt';
import "vuetify/dist/vuetify.min.css";
import './styles/main.scss';
import ConfigFactory from "./config/configFactory";
import AppConfig from "./app.config";
import VueI18n from 'vue-i18n';
import localeEn from "./i18n/locale-en";
import localeUa from "./i18n/locale-uk";
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(ConfigFactory, AppConfig);
Vue.use(AuthJwt);
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueCookie);

const i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'uk-UA',
    messages: {
        "en-US": localeEn,
        "uk-UA": localeUa
    },
});

Vue.config.productionTip = false;

export const vueInstance = new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
