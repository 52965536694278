<template>
    <v-app>
<!--        <navigation v-if="$route.name !== 'dashboard'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>-->
        <toolbar/>
        <v-content>
            <router-view/>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "./Toolbar.vue";
    // import NavigationDrawer from "./NavigationDrawer.vue";
    import SnackDisplayer from "../common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            // navigation: NavigationDrawer,
            SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
