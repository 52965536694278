<template>
    <v-card>
        <v-card-title class="title">Видача коду тестування</v-card-title>
        <v-card-text>
            <div style="border-bottom: 1px solid #ccc;">Ви впевнені, що хочете видати код тестування?</div>
            <div style="height: 6em;" class="d-flex justify-center">
                <v-btn
                    v-if="showBtn"
                    class="mt-5"
                    color="red"
                    dark
                    large
                    @click="saveReworkCode()"
                    :loading="loading"
                >Видати код
                </v-btn>
            </div>
            <div v-if="!error" class="d-flex justify-center mt-5">
                <div class="flex-column">
                    <div class="text-h2">{{code}}</div>
                    <div class="text-center text-caption">Код тестування</div>
                </div>
            </div>
            <v-alert style="font-size: 20px !important; line-height: 30px !important;" type="error" v-if="error">
                <div>Виникла помилка: {{error}}</div>
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click.native="$emit('shouldCloseSaveConfirm')"
                :disabled="loading"
            >Закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import HttpService from "../../../HttpService";

export default {
    data: () => {
        return {
            loading: false,
            showBtn: true,
            code: "_ _ _ _ _ _",
            error: null,
        };
    },
    props: {
        reworkItem: Object,
        isLecture: Boolean,
    },
    mounted() {
        this.showBtn = true;
        this.loading = false;
        this.code = "_ _ _ _ _ _";
        this.error = null;
    },
    methods: {
        saveReworkCode() {
            const url = !this.isLecture ? 'studentReworks/createQuizAttempt' : 'studentReworks/createLectureQuizAttempt';
            this.loading = true;
            this.showBtn = false;

            HttpService.getInstance()
                .post(url, this.reworkItem)
                .then(resp => {
                    this.code = resp.quizAttemptCode;
                    this.loading = false;
                    this.$emit("codeEmited");
                })
                .catch(err => {
                    // this.$emit("codeEmited");
                    this.loading = false;
                    
                    this.error = err.errorMessage;

                    // if (err.errors) {
                    //     this.errors = remapErrors(err.errors);
                    // }
                });
        },
    }
}
</script>