import {rolesEnum} from "@/constants/rolesEnum";

export default (to, from, next) => (router) => {
    if (!router.app.$auth.isAuthenticated()) {
        next({name: 'unauthorized'});
    }

    if (!router.app.$auth.userHasAtLeastOneRole([rolesEnum.adminNb, rolesEnum.admin])) {
        next({name: 'cannot-access'});
    }
    
    next();
}