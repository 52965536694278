<template>
    <div>
        <v-progress-linear
            v-if="loadingState > 0"
            height="3"
            style="margin: 0; z-index: 999; position: absolute"
            :indeterminate="true"
            color="info"
        ></v-progress-linear>
        <v-toolbar
            :color="color"
            dark
            app
            :clipped-left="$vuetify.breakpoint.mdAndUp"
            fixed
        >
            <v-toolbar-title style="width: 250px" class="ml-0 pl-0 cursor-pointer" @click="navigateToHome()">
                <!--                <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')"></v-app-bar-nav-icon>-->
                <toolbar-logo></toolbar-logo>
            </v-toolbar-title>

            <language-switcher/>

            <v-spacer></v-spacer>

            <logout-button/>
            <!--<v-btn v-if="!$auth.isAuthenticated()" @click="$auth.login()" color="warning">-->
            <!--Log in-->
            <!--</v-btn>-->
        </v-toolbar>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import LanguageSwitcher from "../common/LanguageSwitcher";
import HttpService from "../../HttpService";
import LogoutButton from "./LogoutButton";
import ToolbarLogo from "./ToolbarLogo";

export default {
    components: {
        LanguageSwitcher,
        LogoutButton,
        ToolbarLogo,
    },
    props: {
        color: {
            type: String,
            default: 'info'
        }
    },
    computed: {
        ...mapGetters(['selectedNavigationItem', 'getTopNavigationTitle']),
        loadingState() {
            return this.$store.getters['loadingState/loadingState'];
        },
    },
    methods: {
        logout() {
            HttpService.getInstance()
                .post('/authorization/revoke', {token: this.$auth.getRefreshToken()})
                .then(() => {
                    this.$auth.logout();
                    this.$router.push({name: 'code'});
                });
        },
        goToMainDashboard() {
            window.location.href = this.$config.mainDashboardUrl;
        },
        navigateToHome() {
            this.$router.push('/')
                .catch(err => {
                });
        }
    }
}
</script>
<style lang="scss">
.invisible {
    visibility: hidden;
}
</style>