<template>
    <v-card class="mb-1">
        <v-card-text
            class="answer"
            @click="$emit('selectAnswer', answer)"
        >
            <div>{{ answer.answer }}</div>
            <div class="mt-2" v-if="answer.image">
                <img width="350" :src="questionImageUrl(answer.image)"/>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";

let configuration = AppConfig(config);

export default {
    props: {
        answer: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    answer: '',
                }
            }
        },
    },
    methods: {
        questionImageUrl(image) {
            return `${configuration.apiMainEndpoint}/file/get/reworkQuizImage/${image}`;
        },
    }
}
</script>
<style lang="scss">
.answer {
    &:hover {
        cursor: pointer;
        background: #BBDEFB;
    }
}
</style>