<template>
    <v-layout row wrap justify-center>
        <v-flex v-for="item in cardItems" :key="item.titleConst" xs12 sm6 md3>
            <v-card ripple :to="{name: item.link}" :color="item.color" class="white--text elevation-8">
                <v-card-title primary-title>
                    <div class="full-width">
                        <h3 class="title">{{item.title}}</h3>
                    </div>
                    <div class="subtitle-2">{{item.description}}</div>
                </v-card-title>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
    import {cardItemsInitial} from "../../constants/dashboardCardItems";

    export default {
        methods: {
            setCardItems() {
                const mappedCards = cardItemsInitial.map(card => {
                    card.title = this.$t(card.titlePath);
                    card.description = this.$t(card.descriptionPath);

                    return card;
                });

                return mappedCards.filter(card => {
                    return this.$auth.hasRoles(card.role);
                });
            }
        },
        mounted() {
            this.cardItems = this.setCardItems();
        },
        watch: {
            '$i18n.locale': function () {
                this.cardItems = this.setCardItems();
            }
        },
        data: () => {
            return {
                cardItems: [],
            }
        }
    }
</script>
<style lang="scss" scoped>
.full-width {
    & h3.headline {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>