import Vue from 'vue';
import deepmerge from "deepmerge";

export const AppConfig = (config) => {
    let defaultConfig = {};
    let developmentConfig = {};
    let productionConfig = {};

    if (config.hasOwnProperty('default') ) {
        defaultConfig = config.default;
    }
    if (config.hasOwnProperty('development') ) {
        developmentConfig = config.development;
    }
    if (config.hasOwnProperty('production') ) {
        productionConfig = config.production;
    }

    let finalConfig = {};

    if (process.env.NODE_ENV === "development") {
        finalConfig = deepmerge(defaultConfig, developmentConfig);
    }
    if (process.env.NODE_ENV === "production") {
        finalConfig = deepmerge(defaultConfig, productionConfig);
    }

    return finalConfig;
};

export default {
    install: function (Vue, config) {

        Vue.prototype.$config = AppConfig(config);
    }
}