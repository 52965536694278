<template>
    <v-app>
        <student-quiz-toolbar
                :is-lang-switcher="false"
                color="red darken-1"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-content>
            <router-view/>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import StudentQuizToolbar from "../../components/studentQuiz/common/StudentQuizToolbar";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            StudentQuizToolbar,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
    }
</script>
